#home {
    min-height: 450px;
    color: white;
    text-align: center;
    overflow: hidden;
    position: relative;
    justify-content: center;
    object-fit: contain;
}

#home h1 {
    position: absolute;
    z-index: 2;
    width: 100%;
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}