.slideshow-container {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    height: 500px;
    box-sizing: border-box;
}

.slideshow-container img {
    position: absolute;
}

.slide-wrapper {
    transition: transform 1s ease-in-out;
    display: flex;
}

.slide {
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide.active {
    display: block;
    left: 0;
}

.slide.active img {
    display: block;
    visibility: visible;
    opacity: 1;
    margin: 0;
    padding: 0;
}

.slide.prev {
    left: -100%;
}

.slide.next {
    left: 100%;
}