footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
}

footer .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
}

footer .footer-column {
    width: 200px;
    margin-bottom: 20px;
}

footer h4 {
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #f1f1f1;
}

footer ul {
    list-style-type: none;
    padding: 0;
}

footer ul li {
    margin-bottom: 10px;
}

footer ul li a {
    color: #bbbbbb;
    text-decoration: none;
    font-size: 14px;
}

footer ul li a:hover {
    color: #ffffff;
}

.social-icons a {
    color: #ffffff;
    margin: 0 10px;
    font-size: 24px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #bbbbbb;
}

footer p {
    margin-top: 20px;
    font-size: 14px;
    color: #bbbbbb;
    flex-basis: 100%;
    text-align: center;
}

.sign a {
    text-decoration: none;
    color: #bbbbbb;;
}

.sign a:hover {
    color: #ffffff;
}