:root {
    --dynamic-text-size: 16px;
}

body, p{
    font-size: var(--dynamic-text-size);
}

* h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
}

.text-size-changer {
    display: flex;
}

.text-size-changer button {
    padding: 2px 5px;
    font-size: 12px;
    cursor: pointer;
    color: black;
    font-weight: bold;
    border: 1px solid #616060;
    margin-left: 5px;
    background-color: #f4f4f4;
    transition: background-color 0.3s;
}

.text-size-changer button:hover {
    background-color: #e0e0e0;
}
