.logo img {
    border-radius: 50%;
    height: 200px;
}

h1 {
    font-size: 30px;
}

.samarth {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.samarth-column {
    width: 150px;
    margin-bottom: 20px;
}
.samarth-column-2 {
    width: 600px;
    margin-bottom: 20px;
}

.logo2 {
    margin-top: 25px;
}