.slideshow-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 472px;
    box-sizing: border-box;
}

.slide-wrapper {
    transition: transform 1s ease-in-out;
    display: flex;
    width: 100%;
    height: 100%;
}

.slide {
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide img {
    width: 100%;
    height: auto;
}

.slide.active {
    display: block;
    left: 0;
}

.slide.active img {
    display: block;
    visibility: visible;
    opacity: 1;
}

.slide.prev {
    left: -100%;
}

.slide.next {
    left: 100%;
}

.nav-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1;
    padding: 0 20px;
    box-sizing: border-box;
}

.nav-arrows .prev,
.nav-arrows .next {
    cursor: pointer;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 18px;
}

.nav-arrows .prev:hover,
.nav-arrows .next:hover {
    background-color: rgba(0, 0, 0, 0.7);
}