.container{
    display: flex;
    justify-content: space-between;
}

.container button{
    padding: 0;
    border: none;
    background: none;
    color: white;
    margin-left: 10px;
    font-size: 12px;
}