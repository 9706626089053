.top-cloumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1200px;
    margin-bottom: 20px;
}

.social-icons {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
}

.social-icons p {
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
}

.social-icons a {
    margin-right: 10px;
}

.blue {
    background-color: #3a7b91;
    border: 4px solid white;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
}

.brown {
    background-color: #773e0c;
    border: 4px solid white;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
}

.green {
    background-color: #06644d;
    border: 4px solid white;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
}

.language-switcher {
    margin-left: 10px;
}