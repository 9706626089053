/* Header.css */
header {
    background-color: #333;
    color: white;
    padding: 1rem 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    position: relative;
    gap: 2rem;
}

nav ul li {
    position: relative;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 1rem;
    display: block;
}

nav ul li:hover .dropdown {
    opacity: 1;
    visibility: visible;
}

nav ul .dropdown {
    border-top: solid 1px white;
    border-left: solid 1px white;
    border-right: solid 1px white;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 100px;
    z-index: 1000;
    display: block;
    transition: opacity 0.1s ease-in, visibility 0.3s ease-out;
}

nav ul .dropdown li {
    width: 100%;
}

nav ul .dropdown li a {
    border-bottom: solid 1px white;
    display: block;
}

nav ul .dropdown li a:hover {
    background-color: #444;
}